.screen.event-code {
  background-color: #fff;

  nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    color: var(--pink);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);

    div {
      width: 1rem;
      height: fit-content;
      margin-top: 6px;
    }

    h1 {
      font-size: 1.6rem;
    }
  }

  form {
    height: 100%;
  }

  .content {
    height: 100%;
    padding: 1.4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;

    .message {
      h1 {
        font-size: 1.2rem;
        margin-bottom: 1.4rem;
      }

      p {
        margin: 0.4rem 0;
      }
    }

    input {
      background-image: url("../img/ic_password.png");
      background-size: 14px;
      background-repeat: no-repeat;
      background-position-y: center;
      border: 0;
      border-bottom: 1px solid #888;
      box-sizing: border-box;
      width: 100%;
      padding: 0.6rem 0.6rem 0.6rem 2rem;
      margin: 2rem 0 0;
    }

    button {
      margin-bottom: 1rem;
    }
  }
}
