.screen.static {
  background-color: #000;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;

    li {
      height: 100%;
      background-color: #fff;

      h1.header {
        color: var(--pink);
        font-size: 1.4rem;
        text-align: center;
        line-height: 4rem;
        margin: 0;
      }

      nav {
        width: 100%;
        background-color: #ddd;
        padding: 0 1rem;
        display: flex;
        box-sizing: border-box;

        .menu {
          color: #000;
          padding: 1rem 2rem;
          user-select: none;
          font-weight: bold;
          border-bottom: 2px solid var(--pink);
          text-transform: uppercase;
        }
      }

      .confirm {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0.4rem 0 2rem;

        h1 {
          color: var(--pink);
          font-weight: normal;
          font-size: 1.2rem;
        }

        button {
          min-width: 12rem;
        }
      }
    }

    // ----------
    // MAIN
    .main {
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    // ----------
    // LIST
    .list {
      & > div {
        background-color: #fff;
      }

      .content ul {
        li {
          display: grid;
          grid-template-columns: auto 1fr;
          grid-template-rows: 1fr 1fr auto;
          margin: 1.4rem 0;

          &:last-child {
            margin-bottom: 2rem;
          }

          img {
            --size: 6rem;
            grid-row: 1/4;
            width: var(--size);
            height: var(--size);
            margin: 0 1.6rem;
          }

          h1 {
            margin: 0.4rem 0 0;
            font-size: 1rem;
          }

          p {
            margin: 0;
            font-size: 0.8rem;
          }

          button {
            width: fit-content;
            margin-bottom: 0.4rem;
          }
        }
      }
    }

    .text {
      .content ul {
        background-color: #fff;

        li {
          margin-bottom: 0.8rem;

          &:last-child {
            margin-bottom: 2rem;
          }

          div {
            margin: 0 2rem;
            padding: 0.6rem 1rem;
            border: 2px solid #ddd;
            border-radius: 6px;
            color: var(--pink);

            &.active {
              border-color: var(--pink);
              background-color: var(--pink);
              color: #fff;
            }

            p {
              margin: 0;
            }
          }
        }
      }
    }

    .emoji {
      .content ul {
        background-color: #fff;

        li {
          margin-bottom: 0.8rem;

          &:last-child {
            margin-bottom: 2rem;
          }

          div {
            margin: 0 2rem;
            padding: 1rem;
            border: 2px solid #ddd;
            border-radius: 6px;
            color: var(--pink);
            text-align: center;

            &.active {
              border-color: var(--pink);
              background-color: var(--pink);
              color: #fff;

              img {
                filter: brightness(5);
              }
            }

            img {
              max-height: 5rem;
            }

            p {
              margin: 0;
            }
          }
        }
      }
    }

    .comment {
      & > div > .content {
        display: flex;
        flex-direction: column;
        height: calc(50vh - 4rem);

        textarea {
          margin: 1rem 1rem 0;
          padding: 0.6rem;
          height: 100%;
          border: 2px solid var(--pink);
          border-radius: 6px;
        }

        button {
          margin: 1rem;
        }
      }

      .popup {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000a;
        display: flex;

        .content {
          background-color: #fff;
          border-radius: 6px;
          width: 50vw;
          height: fit-content;
          padding: 2rem;
          margin: auto;
          text-align: center;

          p {
            margin: 0.4rem 0;
            font-weight: bold;
            font-size: 1rem;
            text-transform: uppercase;
          }
        }
      }
    }

    .pick,
    .end {
      background-color: #000;
      display: flex;
      align-self: center;

      .content {
        background-color: #fff;
        border-radius: 6px;
        width: 50vw;
        height: fit-content;
        padding: 2rem;
        margin: auto;
        text-align: center;

        img {
          height: 6rem;
          margin-bottom: 1rem;
        }

        p {
          margin: 0.4rem 0;
          font-weight: bold;
          font-size: 1.2rem;
          text-transform: uppercase;
        }

        button {
          margin-top: 1rem;
        }
      }
    }
  }
}
