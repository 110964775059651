.screen.intro {
  justify-content: space-between;
  box-sizing: border-box;
  padding: 5rem 0;

  .logo {
    width: 55%;
    max-height: 40vh;
    text-align: center;
  }
}
