.screen.home {
  background-color: #fff;

  .logo {
    height: 4rem;
    padding: 1rem;
  }

  .menu {
    width: 100%;
    font-size: 0.9rem;

    .slider {
      overflow-x: auto;

      ul {
        background-color: #ddd;
        width: max-content;
        min-width: 100%;
        margin: 0;
        list-style: none;
        display: flex;
        padding: 0 1rem;
        box-sizing: border-box;
        justify-content: space-around;

        li {
          color: #000;
          padding: 0.8rem 1.6rem;
          cursor: pointer;
          user-select: none;

          &.active {
            font-weight: bold;
            border-bottom: 2px solid var(--pink);
          }
        }
      }
    }
  }

  .panel {
    --pages: 4;
    --page: 0;

    width: 100%;
    height: 100%;
    overflow-x: hidden;

    ul {
      list-style: none;
      height: 100%;
      margin: 0;
      padding: 0;
      display: flex;
      width: calc(var(--pages) * 100%);
      transform: translateX(calc(100vw * -1 * var(--page)));
      transition: transform 0.2s;
    }

    li {
      padding: 0.8rem 0;
      width: 100vw;
      height: 100%;
      box-sizing: border-box;

      &.actor,
      &.upcoming,
      &.previous,
      &.checkin {
        --total: 0;
        overflow-x: auto;
      }

      &.actor {
        h1 {
          font-size: 1.4rem;
          margin: 0 1rem;
        }

        .slider > div > div {
          width: 100%;
        }
      }

      &.checkin {
        button {
          width: calc(100% - 2rem);
          margin: 1rem 1rem 0;
        }
        .slider > div > div img {
          height: calc(var(--vh, 1vh) * 100 - 20rem);
        }
      }

      &.upcoming,
      &.previous,
      &.checkin {
        .header,
        .footer {
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          margin: 0 1rem;
        }

        .location {
          font-weight: bold;
        }

        .date {
          color: var(--pink);
          font-size: 0.8rem;
        }

        .slider {
          margin-top: 1rem;

          div img {
            margin-top: 0.4rem;
            margin-bottom: 0.4rem;

            object-fit: contain;
          }
        }
      }

      .slider {
        --size: 82vw;

        display: flex;
        width: calc(var(--total) * var(--size) + (100vw - var(--size)));
        margin: auto;
        scroll-snap-type: x mandatory;

        & > div {
          scroll-snap-align: center;
          width: var(--size);

          &:first-of-type {
            margin-left: calc((100vw - var(--size)) / 2);
          }

          & > div {
            img {
              --margin: 1rem;
              margin: var(--margin);
              width: calc(100% - 2 * var(--margin));
              height: calc(var(--vh, 1vh) * 100 - 18rem);
              min-height: 16rem;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}
