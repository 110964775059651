:root {
  --pink: #dc616d;
  --pinkRGB: 220, 97, 109;
  --pinkActive: #c95a65;
}

html {
  height: 100%;
}

body {
  font-family: "Lato", sans-serif;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

html,
body {
  overflow-x: hidden;
}

.hidden {
  display: none !important;
}

.app {
  // --size: 1;
  // width: calc(0.75 * 100vh * var(--size));
  // height: calc(100% * var(--size));
  // max-width: 100%;

  width: 100%;
  height: 100%;

  background-image: url("../img/ic_background.png");
  background-size: cover;
  background-position: center;

  .screen {
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    display: none;

    &.active {
      display: flex;
    }

    img {
      object-fit: contain;
      max-width: 100%;
      max-height: 100%;
    }

    .loading {
      --size: 80px;
      border: calc(var(--size) * 0.16) solid #fff;
      border-top: calc(var(--size) * 0.16) solid #474773;
      border-radius: 50%;
      width: var(--size);
      height: var(--size);
      animation: spin 1.6s linear infinite;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    button {
      background-color: var(--pink);
      color: #fff;
      font-size: 1.2rem;
      font-weight: bold;
      padding: 1rem;
      border-radius: 6px;
      min-width: 10rem;
      border: 2px solid transparent;
      cursor: pointer;

      &.narrow {
        padding: 0.4rem 1rem;
      }

      &.inverted {
        background-color: #fff;
        color: var(--pink);
        border: 2px solid var(--pink);

        &:active {
          background-color: #ddd;
        }
      }

      &:active {
        background-color: var(--pinkActive);
      }

      &[disabled] {
        background-color: #999;
      }
    }
  }
}

@media (min-aspect-ratio: 3/4) {
  .app {
    --size: 0.98;
  }
}
